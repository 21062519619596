(function ArrayAdd(){
	if(Array.prototype.flat instanceof Function) {
		console.log('Array.protype.flat IS Exist')
	}else {
		Array.prototype.flat = function(i = 1){
			var RES = [];
			var ii = 0;
			(function f(arr, ii){
				var arr = JSON.parse(JSON.stringfy(arr));
				for(let j = 0; j < arr.length; j++){
					if(Array.isArray(arr[j]) && ii < i){
						f(arr[j], ii+1);
					}else {
						RES.push(arr[j]);
					}
				}
			})(this, 0);
			return RES;
		}
	}	
})()
