/***
 * 
 * 设置Title
 * 
 */
import http from "@/components/api/http"

function ArticleTitle(name,to){
	//文章详情和赏聘详情  设置Title
		http.get("api/article/getArticleId/"+to.params.articleId).then(res=>{
			if(res.data.code==200){
				
				document.title=res.data.data.article.title+name
				document.querySelector('meta[name="keywords"]').setAttribute('content',res.data.data.article.title+name)
				document.querySelector('meta[name="description"]').setAttribute('content',res.data.data.article.title+name)
			
			}
		
	})
}

export default function setTile(to,from,next){
	let name;
    if (to.meta.title) {
		
		if(to.name=="rewardDetail" || to.name=="mobilerewardDetail"){
			name="_赏聘网 "
			ArticleTitle(name,to)
		}else{
			document.title = to.meta.title
			document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
			document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
		}
		
	} else {
	
		if(to.name=="ArticleDetails"||to.name=="mobileArticleDetails"){
			
			name="_贸友圈"
			ArticleTitle(name,to)
			
		}else{
			document.title = '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛'
			document.querySelector('meta[name="keywords"]').setAttribute('content',
				'贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈')
			document.querySelector('meta[name="description"]').setAttribute('content',
				'贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等')
		}

		
	}
	if(to.name=="mobilepintuanShare"){
		document.title = '邀请拼团免费领取海关数据-贸友圈'
	}
}