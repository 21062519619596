import { ElMessage } from 'element-plus'


/**
 * 复制链接
 * @param data
 * @returns {string}
 */
export const copyShareLink = (url) => {
  copy(url,'复制成功！赶紧分享给好友吧！');
}

/**
 * 复制文字或链接
 */
export function copy(context,msg) {
  console.log("已复制文字或链接:"+context)
  let input = document.createElement("input");
  document.body.appendChild(input);
  input.setAttribute("value", context);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
  ElMessage({
    type: 'success',
    message: msg,
    offset: 360
  });
}

function _isMobile(){
  const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
  return navigator.userAgent.match(reg) != null;

}

/**
 * 增加date的format方法
 */
Date.prototype.format = function (format) {
  var o = {
      "M+": this.getMonth() + 1, //month
      "d+": this.getDate(), //day
      "h+": this.getHours(), //hour
      "m+": this.getMinutes(), //minute
      "s+": this.getSeconds(), //second
      "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
      "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
      }
  }
  return format;
}


//导出公共方法
export default {
  isMobile(){
    return  _isMobile();
  },
  copyMe(context,msg){
    return copy(context,msg);
  },

}


