import store from "@/store/store.js"
/**
 * 
 * 部分页面背景色变化
 */
function bgChange(pathName) {
    let bgArrList = ["OrderPayment", "rewardManagement", "ArticleManagement", "Myattention", "Mycollect", "Preview", "rewardPreview", "Forum"]
    return bgArrList.map(item => {
        if (pathName == item) {
            return true
        }
    })

}

/**
 * 需要拦截登录的配置
 */
export default function BlackList(isMobile, to, from, next) {

    let type;
    if (bgChange(to.name) || !isMobile) {

        document.querySelector('#app').className = 'bgcolor'
    } else {
        document.querySelector('#app').className = "bgcolor1"
    }

    //localStorage存在就是登录
    if (localStorage.getItem("store")) {
        store.commit("setIsLogin")
        return false
    }

    //要拦截的（登录）页面列表
    let BlackArrList = ["OrderPayment", "PostsList", "PersonalInformation", "PersonalIndex", "Myattention", "Mycollect"
        , "postsDrafts", "MyOrder", "PurchasedServices", "MyBills", "InvoiceIndex", "Pintuan", "SystemMsg", "Settings"
        , "decorate", "mobileLetter", "mobileGuwen", "mobileShoutu", "mobileMyOrder", "mobilePurchasedServices"
        , "mobileRecharge", "mobileSystemMsg", "mobileMyprivate", "mobilemyFollow","mobilecenter", "PersonalIndex"
        , "Recharge", "Apprenticeship", "Letter", "Consultant", "mySupply", "MyQuestion", "MyFollow", "MyReply"
        , "myPrivate", "Preview", "mobileonePreview"
    ]
    //异步登录框列表
    let asyncBlackArrList = ["ArticleRelease", "ArticleAsk", "ArticleNeed", "partner", "shopenter", "shopentering", "reward"]

    asyncBlackArrList.map(item => {
        if (item == to.name) {
            type = 'asyncLogin'
        }
    })
    BlackArrList.map(item => {
        if (item == to.name) {
            type = 'toLogin'
        }
    })

    return type;
}