import Vuex from "vuex";

import newLocalStorage from "@/utils/newlocalStorage"

import createPersistedState from "vuex-persistedstate";

let store_info = {};
var local_store= localStorage.getItem("store")
if(local_store && local_store !="undefined"){
  store_info = JSON.parse(local_store);
  //console.log(store_info)
}
const store = new Vuex.Store({
  state: {
    token:store_info ?  store_info.token : '',
    isLogin: store_info ? store_info.isLogin : false,
    userInfo: store_info ? store_info.userInfo : {},
    message: {},
    shareProduct: {},
    indexLogin:false,
    invitedId:"",
    ArticleContent:''
  },
 
  mutations: {
    setToken(state, token) {
      state.token = token;
    },  
    delToken(state) {
      localStorage.clear()
      state.token=''
      state.isLogin=false
      state.userInfo={}
    },
    setIsLogin(state, isLogin) {
      state.isLogin=store_info.isLogin 
      state.userInfo= store_info.userInfo 
      state.token=store_info.token 
    },
    ArticleContent(state,Content){
      console.log(Content,'Content')
      // this.ArticleContent=Content
      sessionStorage.setItem("ArticleContent",JSON.stringify(Content))
    },
    setUserInfo(state, userInfo) {
      /**
       * 非法改值
       */
      // console.log(userInfo,'--------------',state.userInfo)
    // userInfo.password = ""; //密码不要显示在前端
    
    // state.userInfo = userInfo;
    },
    setInfo(state,userInfo){
      store_info.userInfo.email=userInfo.email
      store_info.userInfo.username=userInfo.username
      store_info.userInfo.industryId=userInfo.industryId
      localStorage.setItem("store",JSON.stringify({
          isLogin:store_info.isLogin,
          token:store_info.token,
          userInfo:store_info.userInfo
      }));
    },
    setMessageId(state, message) {
      state.message = message;
    },  
    setShareProdut(state, shareProduct) {
      state.shareProduct = shareProduct;
    },
    indexLogin(state, staute){
      state.indexLogin=staute
    },
   
  },
 
 
});
// console.log(store.state)

export default store;
