const routesM=[
    {
    path: '/m/register',
    name: 'MobileRegister',
    component: () => import('@/mobile/logreg/register'),
        meta: {
            title: '注册-贸友圈',
            keywords: '贸友圈，注册贸友圈，贸友圈怎么注册',
            description: '注册贸友圈加入外贸朋友圈，交流交友、传道、授业、解惑也！'
        },
    },
    {
        path: '/m/forget',
        name: 'MobileForget',
        component: () => import('@/mobile/logreg/forget')
    },
    {
        path: '/m/resetPassword',
        name: 'MobileResetPassword',
        component: () => import('@/mobile/logreg/resetPassword'),
        
    },
    {
        path: '/m/userAgreement',
        name: 'MobileUserAgreement',
        component: () => import('@/mobile/logreg/userAgreement')
    },
    {
        path: '/m/privacyPolicy',
        name: 'MobilePrivacyPolicy',
        component: () => import('@/mobile/logreg/privacyPolicy')
    },
    {
        path: '/m/share',
        name: 'MobileShare',
        component: () => import('@/mobile/share/share'),
        meta: {
            title: '你分享我给钱-贸友圈',
            keywords: '贸友圈，推广大使，你分享我给钱，多分享多赚钱',
            description: '分享贸友圈的一切，均有机会获得收益，收益可在线提现'
        }
    },
    {
        path: '/m/member/personalInformation',
        name: 'MobilePersonalInformation',
        component: () => import('@/mobile/member/personalInformation')
    },
    {
        path: '/m/memberPackage',
        name: 'MobileMemberPackage',
        component: () => import('@/mobile/products/memberPackage'),
        meta: {
            title: 'VIP会员福利-贸友圈｜海关数据、全网搜邮、邮箱检测、邮件群发',
            keywords: '贸友圈VIP会员福利，海关数据，全网搜邮，邮箱检测，邮件群发',
            description: '贸友圈致力为VIP会员谋福利，帮你把关筛选更好的第三方产品服务且更低价，多分享多赚钱还能赢得超额返现！'
        }
    },
    {
        path: '/m/orderPayment',
        name: 'MobileOrderPayment',
        component: () => import('@/mobile/member/payment/orderPayment.vue')
    },
    {
        path: '/m/orderCompleted',
        name: 'MobileOrderCompleted',
        component: () => import('@/mobile/member/payment/orderCompleted.vue'),
    },
    {
        path: '/m',
        name: 'newHomePage',
        component: () => import('@/mobile/homePage/newHomePage'),
       
        redirect:"/m/articleIndex/all",
        children:[
            {
                path: 'articleIndex/:sectionId',
                name: 'mobileArticleIndex',
                component: () => import('@/mobile/article/articleIndex'),
                        meta: {
                        title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                        keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                        description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    }
            },
            		{
                        path: 'rewardDetail/:articleId',
                        name: 'mobilerewardDetail',
                        meta: {
                            title: '免费的跨境电商人才招聘网站_赏聘网',
                            keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
                            description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
                        },
                        Reward:{Reward:true},
                        component: () => import('@/page/job/rewardDetail.vue'),
                    },
            {
                path: 'articleDetails/:articleId',
                name: 'mobileArticleDetails',
                component: () => import('@/mobile/article/articleDetails')
            },
            {
                path: 'preview',
                name: 'mobileonePreview',
                component: () => import('@/mobile/article/preview.vue'),
               
            },
            {
                path: 'articleRelease',
                name: 'mobilearticleRelease',
                component: () => import('@/mobile/article/articleRelease.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                        to.query.path=from.fullPath
                        next()
                    
                },
            },
            {
                path:'hisguwen',
                name:'mobileHisguwen',
                component: () => import('@/mobile/member/hisHomepage/hisGuwen.vue')
            },
            {
                path:'hisShoutu',
                name:'mobileHisShoutu',
                component: () => import('@/mobile/member/hisHomepage/hisShoutu.vue')
            },
            {
                path:'letter',
                name:"mobileLetter",
                component: () => import('@/mobile/member/center/letter.vue')
            },
            {
                path:'guwen',
                name:"mobileGuwen",
                component: () => import('@/mobile/member/center/guwen.vue')
            },
            {
                path:'shoutu',
                name:"mobileShoutu",
                component: () => import('@/mobile/member/center/shoutu.vue')
            },
            {
                path: 'myOrder',
                name: 'mobileMyOrder',
                meta: {
                        title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                        keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                        description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                
                    link:true
                },
                component: () => import('@/mobile/member/myOrder/myOrder')
            },
            {
                path: 'articleModify',
                name: 'mobilearticleModify',
                component: () => import('@/mobile/article/articleModify.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    
                    to.query.path=from.fullPath
                    next()
                
            },
            },
            {
                path: 'articleAsk',
                name: 'mobilearticleAsk',
                component: () => import('@/mobile/article/articleAsk.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    to.query.path=from.fullPath
                    next()
            },
            },
            {
                path: 'articleneed',
                name: 'mobilearticleneed',
                component: () => import('@/mobile/article/articleneed.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
            
            },
            {
            	path: 'articlereward',
            	name: 'mobilearticlereward',
            	component: () => import('@/mobile/article/articlereward.vue'),
            	meta: {
            		title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            	keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            	description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            		link:true
            	},
    
            },
            {
            	path: 'articlepartner',
            	name: 'mobilearticlepartner',
            	component: () => import('@/mobile/article/articlepartner.vue'),
            	meta: {
            		title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            		keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            		description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            		link:true
            	},
    
            },
            {
                path: 'shopenter',
                name: 'mobileshopenter',
                component: () => import('@/mobile/article/shopEnter.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
    
            },
            {
            	path: 'articlepartnerModify',
            	name: 'mobilearticlepartnerModify',
            	component: () => import('@/mobile/article/articlepartnerModify.vue'),
            	meta: {
            		title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            		keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            		description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            		link:true
            	},
        
            },
            {
            	path: 'articlerewardModify',
            	name: 'mobilearticlerewardModify',
            	component: () => import('@/mobile/article/articlerewardModify.vue'),
            	meta: {
            		title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            		keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            		description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            		link:true
            	},
        
            },
            {
                path: 'articleSupplyModify',
                name: 'mobilearticleSupplyModify',
                component: () => import('@/mobile/article/articleSupplyModify.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
            // 	beforeEnter: (to,from,next)=>{
            // 		to.query.path=from.fullPath
            // 		next()
            // },
            },
            {
                path: 'articleAskModify',
                name: 'mobilearticleAskModify',
                component: () => import('@/mobile/article/articleAskModify.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    
                    to.query.path=from.fullPath
                    next()
                
            },
            },
            {
                path: 'articleManagement',
                name: 'mobilearticleManagement',
                component: () => import('@/mobile/article/articleManagement.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                }
            },
            {
                path: 'originalStatement',
                name: 'mobileoriginalStatement',
                component: () => import('@/mobile/logreg/originalStatement.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                }
            },
            {
                path: 'postsList',
                name: 'mobilecenter',
                component: () => import('@/mobile/member/center/center.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    if(to.query.path){
                        next()
                        return 
                    }else{
                        to.query.path=from.fullPath
                        next()
                    }
                },
            },
            {
                path: 'pintuan',
                name: 'mobilepintuan',
                component: () => import('@/mobile/member/center/pintuan.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    if(to.query.path){
                        next()
                        return
                    }else{
                        to.query.path=from.fullPath
                        next()
                    }
                    
                },
            },
            {
                path: 'pintuanDetil',
                name: 'mobilepintuanDetil',
                component: () => import('@/mobile/member/center/pintuanDetil.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    if(to.query.path){
                        next()
                        return
                    }else{
                        to.query.path=from.fullPath
                        next()
                    }
                    
                },
            },
        
            {
                path: 'drafts',
                name: 'mobiledrafts',
                component: () => import('@/mobile/member/center/drafts.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true
                },
                beforeEnter: (to,from,next)=>{
                    if(to.query.path){
                        next()
                        return
                    }else{
                        if(!(from.path=='/m/member/postsList')){
                            to.query.path=from.fullPath
                        }
                        next()
                    }
                    
                },
            },
            {
                path: 'customsCode',
                name: 'mobileCustomsCode',
                component: () => import('@/mobile/customsCode/customsCodeIndex'),
                meta: {
                    title: '免费HS海关编码查询-贸友圈｜申报实例、申报要素、退税率查询',
                    keywords: '贸友圈，HS海关编码查询,HS Code查询,申报实例，申报要素，出口退税率查询，海关编码查询，商品编码查询',
                    description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
                    link:true
                }
            },
            {
                path: 'customsDetil',
                name: 'mobileCustomsCodeDetils',
                component: () => import('@/mobile/customsCode/customDetils'),
                meta: {
                    title: '免费HS海关编码查询-贸友圈｜申报实例、申报要素、退税率查询',
                    keywords: '贸友圈，HS海关编码查询,HS Code查询,申报实例，申报要素，出口退税率查询，海关编码查询，商品编码查询',
                    description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
                    
                }
            },
            {
                path: 'customSeach',
                name: 'mobilecustomSeach',
                component: () => import('@/mobile/customsCode/customSeach'),
                meta: {
                    title: '免费HS海关编码查询-贸友圈｜申报实例、申报要素、退税率查询',
                    keywords: '贸友圈，HS海关编码查询,HS Code查询,申报实例，申报要素，出口退税率查询，海关编码查询，商品编码查询',
                    description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
                    
                }
            },
            
            {
                path: 'orderDetails',
                name: 'mobileorderDetails',
                component: () => import('@/mobile/member/myOrder/orderDetails')
            },
            {
                path: 'purchasedServices',
                name: 'mobilePurchasedServices',
                
                component: () => import('@/mobile/products/services')
            },
            {
                path: 'orderDetails',
                name: 'mobileorderDetails',
                component: () => import('@/mobile/member/myOrder/orderDetails')
            },
        
            {
                path: 'myprivate',
                name: 'mobileMyprivate',
                meta:{
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    link:true},
                component: () => import('@/mobile/member/center/myprivate')
            },
        
        
            // {
            // 	path: 'circle',
            // 	name: 'MobileHomePage',
            // 	component: () => import('@/mobile/homePage/indexNew'),
            // 	redirect:'/articleIndex/all',
            // 	children:[
            // 		{
            // 			path: 'articleIndex/:sectionId',
            // 			name: 'mobileArticleIndex',
            // 			component: () => import('@/mobile/article/articleIndex')
            // 		}
            // 	],
            // 	meta: {
            // 		title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            // 		keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            // 		description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            // 	}
            // },
            {
                path:"active",
                name:"Active",
                component: () => import('@/mobile/signIn/index.vue'),
            },
            {
                path:'personalData',
                name: 'mobilePersonalData',
                component: () => import('@/mobile/member/myOrder/personalData'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }
        
            },
            {
                path:'Mycollect',
                name: 'mobileMycollect',
                component: () => import('@/mobile/member/center/Mycollect'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }
        
            },
            {
                path:'Myactivity',
                name: 'mobileMyactivity',
                component: () => import('@/mobile/member/center/Myactivity'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }

            },
            {
                path:'myFollow',
                name: 'mobilemyFollow',
                component: () => import('@/mobile/member/center/myFollow'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }
        
            },
            {
                path: 'need',
                name: 'mobileNeed',
                component: () => import('@/mobile/member/need/need'),
                meta: {
                    title: '外贸供求信息_贸友圈｜免费发布为你引流赢得商机',
                    keywords: '外贸供求信息网，物流供求信息网、国际展会供求信息网、库存供求信息网',
                    description: '贸友圈-外贸供求，旨在帮助外贸朋友们找到更适合的供应合作伙伴！'
                }
            },
            {
                path: 'shop',
                name: 'mobileshop',
                component: () => import('@/mobile/FindServeShop/shop'),
                meta: {
                    title: '发现优质外贸服务商_服务市场_贸友圈',
                    keywords: '优质外贸服务商、外贸服务市场、国际物流服务商、国际展会服务商、独立站服务商、海外营销推广服务商、外贸软件服务商',
                    description: '贸友圈外贸服务市场，汇集了培训直播、建站推广、国际展会、国际物流、外贸软件、财税法务、商标注册等优质服务商，旨在帮助外贸朋友们找到身边更优秀的外贸服务商！'
                }
            },
            {
                path: 'shopDetails/:details',
                name: 'MobilesHopDetails',
                component: () => import('@/mobile/FindServeShop/shopDetails'),
                
            },
            {
                path: '/m/recharge',
                name: 'mobileRecharge',
                meta: {
                title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                },
                component: () => import('@/mobile/member/payment/recharge')
            },
        ]
    },
    {
        path: '/m/pintuanShare',
        name: 'mobilepintuanShare',
        component: () => import('@/mobile/share/pintuanShare.vue'),
        meta: {
            title: '邀请拼团免费使用海关数据-贸友圈',
            keywords: '邀请拼团免费使用海关数据-贸友圈',
            description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
            link:true
        },
        
    },
    {
        path: '/m/systemIndex',
        name: 'mobileSystemMsg',
        meta:{
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
        keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
        description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            link:true},
        component: () => import('@/mobile/member/system/system')
    },
    	{
			path:'/m/rewardDetail/:articleId',
			name:'mobilerewardDetail',
			meta: {
				title: '赏聘网_免费的跨境电商人才招聘网站',
				keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
				description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！',
				hasMobile:{hasMobile:true}
			},
			component: () => import('@/mobile/article/rewardDetails.vue'),
			

		},
        	
		{
			path:'/m/RewardHomePage',
			name:'mobileRewardHomePage',
			meta: {
				title: '赏聘网_免费的跨境电商人才招聘网站',
				keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
				description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
			},
			component: () => import('@/mobile/homePage/RewardHomePage')
		},
    {
        path: '/m/pintuanShare2',
        name: 'mobilepintuanShare2',
        component: () => import('@/mobile/share/pintuanShare2.vue'),
        meta: {
            title: '邀请拼团免费使用海关数据-贸友圈',
            keywords: '邀请拼团免费使用海关数据-贸友圈',
            description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
            link:true
        },
        
    },
    {
        path:'/m/hisIndex',
        name: 'mobileHisIndex',
        component: () => import('@/mobile/member/hisHomepage/hisIndex'),
        meta: {
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
        },
        children:[
            {
                path:'hisPost',
                name: 'mobilehisPost',
                component: () => import('@/mobile/member/hisHomepage/hisPost'),
            },
            {
                path:'hisQuestion',
                name: 'mobilehisQuestion',
                component: () => import('@/mobile/member/hisHomepage/hisQuestion'),
            },
            {
                path:'hisSupply',
                name: 'mobilehisSupply',
                component: () => import('@/mobile/member/hisHomepage/hisSupply'),
            }
        ]
    },
    {
        path: '/m/one',
        name: 'mobileone',
        component: () => import('@/mobile/member/need/message.vue')
    },
    
    {
        path: '/m/biographical',
        name: 'mobilebiographical',
        component: () => import('@/mobile/member/center/biographical.vue')
    },
    
    
    {
        path: '/m/state',
        name: 'MobileIndex1',
        component: () => import('@/mobile/homePage/index'),
        meta: {
            title: '全球海关数据&全网搜邮-贸友圈｜数据多、更新快、更好用',
            keywords: '贸友圈，查询全球海关数据，海关进出口数据，免费权威海关数据，进出口贸易数据，国外采购商信息，怎么找采购负责人联系方式',
            description: '贸友圈为外贸人谋福利啦，最新全球各国海关数据&全网搜邮&邮箱检测邮件群发，数据多、更新快、效果好，助您业绩增长！'
        }
    },
]


export default routesM