import { decrypt } from '@/utils/CryptoJS'
import store from '@/store/store'
const routesPC = [
/*
    // 通过别名来配置首页 ==》alias:['/','/index.html'],    
   {
        path: '/',
        redirect: '/customsData',
    },
    {
        path: '/index.html',
        redirect: '/customsData',
    },
 */
    {
        path: '/preview',
        name: 'Preview',
        meta: {
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
        },
        component: () => import('@/page/article/preview.vue'),
    },
    {
        path: '/rewardPreview/:message',
        name: 'rewardPreview',
        meta: {
            title: '赏聘网_专业的跨境电商人才招聘网站',
            keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
            description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
        },
        component: () => import('@/page/article/rewardPreview.vue'),
    },
    {

        path: '/job',
        name: 'Job',
        meta: {
            title: '赏聘网_专业的跨境电商人才招聘网站',
            keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
            description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
        },
        component: () => import('@/page/job/index.vue'),
    },
    // {
    // 	path: '/rewardHomepage',
    // 	name: 'rewardHomepage',
    // 	component: () => import('@/page/homePage/rewardHomepage.vue'),
    // 	redirect:'/rewardHomepage/rewardIndex',
    // 	meta: {
    // 		title: '免费的跨境电商人才招聘网站_赏聘网',
    // 		keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
    // 		description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！',
    // 		Reward:{Reward:true}
    // 	},
    // },
    {
        path: '/rewardDetail/:articleId',
        name: 'rewardDetail',
        meta: {
            title: '赏聘网_免费的跨境电商人才招聘网站',
            keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
            description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！',
            hasMobile: true
        },
        component: () => import('@/page/job/rewardDetail.vue'),
    },
    {
        path: '/rewardManagement',
        name: 'rewardManagement',
        meta: {
            title: '赏聘网_免费的跨境电商人才招聘网站',
            keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
            description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
        },
        component: () => import('@/page/job/rewardManagement.vue'),
    },
    // 	children:[
    // 		// {
    // 		// 	path: 'rewardIndex',
    // 		// 	name: 'rewardIndex',
    // 		// 	meta: {
    // 		// 		title: '免费的跨境电商人才招聘网站_赏聘网',
    // 		// 		keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
    // 		// 		description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
    // 		// 	},
    // 		// 	component: () => import('@/page/job/index.vue'),
    // 		// },
    // 		{
    // 			path: '/rewardDetail/:articleId',
    // 			name: 'rewardDetail',
    // 			meta: {
    // 				title: '免费的跨境电商人才招聘网站_赏聘网',
    // 				keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
    // 				description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
    // 			},
    // 			Reward:{Reward:true},
    // 			component: () => import('@/page/job/rewardDetail.vue'),
    // 		},
    // 		{
    // 			path: 'rewardManagement',
    // 			name: 'rewardManagement',
    // 			meta: {
    // 				title: '免费的跨境电商人才招聘网站_赏聘网',
    // 				keywords: '免费的外贸人才招聘网站、免费的电商人才招聘网站、赏聘网',
    // 				description: '赏聘网是一个免费的跨境电商人才外贸人才招聘平台，为出口企业提供免费招人服务，为求职者推荐高薪职位！'
    // 			},
    // 			component: () => import('@/page/job/rewardManagement.vue'),
    // 		}
    // 	]

    // },


    {
        path: '/articleIndex',
        name: 'HomePage',
        component: () => import('@/page/homePage/forum'),
        children: [
            {
                path: ':sectionId',
                alias:['/articleIndex'],
                name: 'ArticleIndex',
                component: () => import('@/page/homePage/forum')
            },

        ],
        meta: {
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
            isMobile: "mobileArticleIndex"
        },

    },
    {
        path: '/forum',
        name: 'Forum',
        component: () => import('@/page/homePage/forum'),
        children: [
            {
                path: ':sectionId',
                alias:['/forum'],
                name: 'Forum',
                component: () => import('@/page/homePage/forum')
            },

        ],
        alias:['/'],
        meta: {
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',

        },
    },

    {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('@/page/about/aboutUs'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/page/logreg/login'),
        meta: {
            title: '登录-贸友圈',
            keywords: '贸友圈，登录贸友圈',
            description: '登录贸友圈，构建你的外贸朋友圈，传道、授业、解惑也！',
            hasMobile: true
        }
    },
    {
        path: '/server',
        name: 'server',
        component: () => import('@/page/logreg/login'),
        meta: {
            title: '登录-贸友圈',
            keywords: '贸友圈，登录贸友圈',
            description: '登录贸友圈，构建你的外贸朋友圈，传道、授业、解惑也！',
            hasMobile: true
        }
    },
    //新增找服务商
    {
        path: '/FindServeShop',
        name: 'FindServeShop',
        redirect: "/shopenter",
        component: () => import('@/page/FindServeShop/enter.vue'),
        meta: {
            title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
            keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
            description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
        },
        children: [
            {
                path: '/shopenter',
                name: 'shopenter',
                component: () => import('@/components/shop/enter.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }
            },
            {
                path: '/shopentering',
                name: 'shopentering',
                component: () => import('@/components/shop/now.vue'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                }
            },
        ]
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import('@/page/FindServeShop/shop'),
        meta: {
            title: '发现优质外贸服务商_服务市场_贸友圈',
            keywords: '优质外贸服务商、外贸服务市场、国际物流服务商、国际展会服务商、独立站服务商、海外营销推广服务商、外贸软件服务商',
            description: '贸友圈外贸服务市场，汇集了培训直播、建站推广、国际展会、国际物流、外贸软件、财税法务、商标注册等优质服务商，旨在帮助外贸朋友们找到身边更优秀的外贸服务商！'
        }
    },
    //入驻
    {
        path: '/need',
        name: 'need',
        component: () => import('@/page/need/need'),
        meta: {
            title: '外贸供求信息_贸友圈｜免费发布为你引流赢得商机',
            keywords: '外贸供求信息网，物流供求信息网、国际展会供求信息网、库存供求信息网',
            description: '贸友圈-外贸供求，旨在帮助外贸朋友们找到更适合的供应合作伙伴！'
        }
    },
    {
        path: '/reward',
        name: 'reward',
        component: () => import('@/page/article/reward')
    },
    {
        path: '/rewardModify',
        name: '/rewardModify',
        component: () => import('@/page/article/rewardModify')
    },
    {
        path: '/partnerModify',
        name: '/partnerModify',
        component: () => import('@/page/article/partnerModify')
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import('@/page/article/partner')
    },
    {
        path: '/articleNeed',
        name: 'ArticleNeed',
        component: () => import('@/page/need/damen.vue')
    },
    {
        path: '/one',
        name: 'one',
        component: () => import('@/page/need/message.vue')
    },
    {
        path: '/supply',
        name: 'supply',
        component: () => import('@/page/need/supply.vue')
    },
    {
        path: '/articleSupplyModify',
        name: 'articleSupplyModify',
        component: () => import('@/page/article/articleSupplyModify.vue')
    },


    //供需
    {
        path: '/m/login',
        name: 'MobileLogin',
        component: () => import('@/mobile/logreg/login'),
        meta: {
            title: '登录-贸友圈',
            keywords: '贸友圈，登录贸友圈',
            description: '登录贸友圈，构建你的外贸朋友圈，传道、授业、解惑也！'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/page/logreg/register'),
        meta: {
            title: '注册-贸友圈',
            keywords: '贸友圈，注册贸友圈，贸友圈怎么注册',
            description: '注册贸友圈，加入外贸朋友圈，交流交友、传道、授业、解惑也！',
            hasMobile: true
        }
    },

    {
        path: '/forget',
        name: 'Forget',
        component: () => import('@/page/logreg/forget'),
        meta: {
            hasMobile: true
        }
    },

    {
        path: '/resetPassword',
        name: 'ResetPassword',
        component: () => import('@/page/logreg/resetPassword')
    },

    {
        path: '/userAgreement',
        name: 'UserAgreement',
        component: () => import('@/page/logreg/userAgreement'),
        meta: {
            hasMobile: true
        }
    },
    {
        path: '/originalStatement',
        name: 'OriginalStatement',
        component: () => import('@/page/logreg/originalStatement'),
        meta: {
            hasMobile: true
        }
    },

    {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('@/page/logreg/privacyPolicy'),
        meta: {
            hasMobile: true
        }
    },

    {
        path: '/articleRelease',
        name: 'ArticleRelease',
        component: () => import('@/page/article/articleRelease')
    },
    {
        path: '/articleAsk',
        name: 'ArticleAsk',
        component: () => import('@/page/article/articleAsk')
    },
    {
        path: '/articleModify',
        name: 'ArticleModify',
        component: () => import('@/page/article/articleModify')
    },
    {
        path: '/articleAskModify',
        name: 'ArticleAskModify',
        component: () => import('@/page/article/articleAskModify')
    },
    // {
    // 	path: '/articleIndex/:sectionId',
    // 	name: 'ArticleIndex',
    // 	component: () => import('@/page/article/articleIndex')
    // },

    {
        // 
        path: '/articleDetails/:articleId',
        name: 'ArticleDetails',
        meta: {
            hasMobile: true
        },
        component: () => import('@/page/article/articleDetails'),

    },
    {
        path: '/articleManagement',
        name: 'ArticleManagement',
        component: () => import('@/page/article/articleManagement')
    },
    {
        path: '/show',
        name: 'show',
        component: () => import('@/page/article/show')
    },
    {
        path: '/createCircles',
        name: 'CreateCircles',
        component: () => import('@/page/circle/createCircles'),
        redirect: '/certification',
        children: [{
            path: '/certification',
            name: 'Certification',
            component: () => import('@/page/circle/certification')
        },
        {
            path: '/createCirclesIndex',
            name: 'CreateCirclesIndex',
            component: () => import('@/page/circle/createCirclesIndex')
        },
        {
            path: '/applyCircleOwner',
            name: 'ApplyCircleOwner',
            component: () => import('@/page/circle/applyCircleOwner')
        }
        ]
    },
    // {
    // 	path: '/share',
    // 	name: 'Share',
    // 	component: () => import('@/page/share/share'),
    // 	meta: {
    // 		title: '你分享我给钱-贸友圈',
    // 		keywords: '贸友圈，推广大使，你分享我给钱，多分享多赚钱',
    // 		description: '分享贸友圈的一切，均有机会获得收益，收益可在线提现',
    // 		hasMobile: true
    // 	}
    // },

    {
        path: '/sharePost',
        name: 'SharePost',
        component: () => import('@/page/share/sharePost'),
        meta: {
            title: '你分享我给钱-贸友圈',
            keywords: '贸友圈，推广大使，你分享我给钱，多分享多赚钱',
            description: '分享贸友圈的一切，均有机会获得收益，收益可在线提现',
            hasMobile: true
        }
    },


    {
        path: '/paymoney',
        name: 'Paymoney',
        component: () => import('@/page/member/payment/paymoney')
    },
    {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/page/member/payment/withdraw')
    },


    {
        path: '/accountBinding',
        name: 'AccountBinding',
        component: () => import('@/page/member/binding/binding'),
    },

    {
        path: '/createSection',
        name: 'CreateSection',
        component: () => import('@/page/member/forum/createSection'),
        beforeEnter(to, from, next) {
            if (!store.state.isLogin) {
                next('/login')

                return false
            }
            next()

        }
    },
    {
        path: '/sectionList',
        name: 'SectionList',
        component: () => import('@/page/member/forum/sectionList'),
        beforeEnter(to, from, next) {
            if (!store.state.isLogin) {
                next('/login')
                return false
            }
            next()
        }
    },
    {
        path: '/member',
        name: 'Member',
        component: () => import('@/page/member/rootIndex.vue'),
        redirect: '/member/postsList',
        children: [
            {
                path: 'postsList',
                name: 'PostsList',
                meta: { isMember: true },
                component: () => import('@/page/member/posts/postsList'),
            },
            // {
            // 	path: 'personalIndex',
            // 	name: 'PersonalIndex',
            // 	component: () => import('@/page/member/center/index'),
            // },
            {
                path: 'recharge',
                name: 'Recharge',
                component: () => import('@/page/member/payment/recharge')
            },
            // {
            // 	path: '/u/:hisIndex',
            // 	name: 'HisIndex',
            // 	meta:{isMember:true},
            // 	component: () => import('@/page/member/hisHomepage/index')
            // },
            {
                path: '/u/:hisIndex',
                name: 'HisIndex',
                meta: { isMember: true },
                component: () => import('@/page/member/hisHomepage/hisPost')
            },
            {
                path: 'pintuan',
                name: 'Pintuan',
                component: () => import('@/page/member/pintuan/index.vue'),
                meta: {
                    hasMobile: true
                },

            },
            {
                path: '/hisConsultant/:hisIndex',
                name: 'hisConsultant',
                component: () => import('@/page/member/hisHomepage/hisConsultant')
            },
            {
                path: '/hisapprenticeship/:hisIndex',
                name: 'hisApprenticeship',
                component: () => import('@/page/member/hisHomepage/hisApprenticeship')
            },
            // {
            // 	path: 'shareSet',
            // 	name: 'ShareSet',
            // 	component: () => import('@/page/share/shareSet'),
            // 	meta: {
            // 		title: '你分享我给钱-贸友圈',
            // 		keywords: '贸友圈，推广大使，你分享我给钱，多分享多赚钱',
            // 		description: '分享贸友圈的一切，均有机会获得收益，收益可在线提现',
            // 		hasMobile: true
            // 	}
            // },
            {
                path: 'hisFollow',
                name: 'HisFollow',
                component: () => import('@/page/member/hisHomepage/hisFollow')
            },
            {
                path: 'invoiceIndex',
                name: 'InvoiceIndex',
                component: () => import('@/page/member/invoice/index.vue'),
                meta: {
                    hasMobile: true
                },

            },
            {
                path: 'invoiceList',
                name: 'InvoiceList',
                component: () => import('@/page/member/invoice/list.vue'),
                meta: {
                    hasMobile: true
                },
                children: [
                    { path: "detil", name: "detil", component: () => import('@/page/member/invoice/detil.vue') },
                    { path: "table", name: "table", component: () => import('@/page/member/invoice/table.vue') }
                ]
            },

            {
                path: '/hisQuestion/:hisIndex',
                name: 'HisQuestion',
                component: () => import('@/page/member/hisHomepage/hisQuestion')
            },
            {
                path: '/hisHire/:hisIndex',
                name: 'HisHire',
                component: () => import('@/page/member/hisHomepage/hisHire')
            },
            {
                path: '/hisSupply/:hisIndex',
                name: 'HisSupply',
                component: () => import('@/page/member/hisHomepage/hisSupply.vue')
            },
            // {
            // 	path: '/hisPost/:hisIndex',
            // 	name: 'HisPost',
            // 	component: () => import('@/page/member/hisHomepage/hisPost')
            // },

            {
                path: 'myFollow',
                name: 'MyFollow',
                component: () => import('@/page/member/center/myFollow')
            },
            // {
            // 	path: 'myPost',
            // 	name: 'MyPost',
            // 	component: () => import('@/page/member/center/myPost')
            // },
            {
                path: 'personalIndex',
                name: 'PersonalIndex',
                component: () => import('@/page/member/center/myPost')
            },
            {
                path: 'myQuestion',
                name: 'MyQuestion',
                component: () => import('@/page/member/center/myQuestion')
            },
            {
                path: 'myPrivate',
                name: 'myPrivate',
                meta: { hasMobile: true },
                component: () => import('@/page/member/center/myprivate')
            },
            {

                path: 'mySupply',
                name: 'mySupply',
                component: () => import('@/page/member/center/mySupply')
            },


            {
                path: 'myReply',
                name: 'MyReply',
                component: () => import('@/page/member/center/myReply')
            },

            {
                path: 'personalInformation',
                name: 'PersonalInformation',
                component: () => import('@/page/member/personalInformation'),
                meta: {
                    hasMobile: true
                },

            },
            {
                path: 'curriculumVitae',
                name: 'curriculumVitae',
                component: () => import('@/page/member/curriculumVitae'),
            },
            {
                path: 'decorate',
                name: 'decorate',
                component: () => import('@/page/member/decorate/decorate')
            },
            {
                path: 'postsDrafts',
                name: 'postsDrafts',
                component: () => import('@/page/member/posts/postsDrafts'),

            },
            {
                path: 'purchasedServices',
                name: 'PurchasedServices',
                meta: { isMember: true },
                component: () => import('@/page/member/services/services'),

            },
            {
                path: 'myOrder',
                name: 'MyOrder',
                meta: { isMember: true },
                component: () => import('@/page/member/myOrder/myOrder'),

            },

            {
                path: 'myBills',
                name: 'MyBills',
                component: () => import('@/page/member/myBills/myBills'),

            },
            {
                path: 'apprenticeship',
                name: 'Apprenticeship',
                component: () => import('@/page/member/center/apprenticeship'),

            },
            {
                path: 'consultant',
                name: 'Consultant',
                component: () => import('@/page/member/center/consultant'),

            },
            {
                path: 'letter',
                name: 'Letter',
                component: () => import('@/page/member/center/letter')
            },
            {
                path: 'systemIndex',
                name: 'SystemMsg',
                meta: { hasMobile: true },
                component: () => import('@/page/member/system/system')
            },
            {
                path: 'Myattention',
                name: 'Myattention',
                component: () => import('@/components/article/homePageIndex'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    hasMobile: true
                }
            },
            {
                path: 'Mycollect',
                name: 'Mycollect',
                component: () => import('@/components/article/homePageIndex'),
                meta: {
                    title: '贸友圈_外贸论坛&电商论坛_能帮你赚钱的新型论坛',
                    keywords: '贸友圈，外贸论坛B2B，跨境电商论坛B2C，跨境电商社区，外贸朋友圈',
                    description: '贸友圈是一个能帮你赚钱的新型外贸论坛，可以免费发布文章、供求信息、招聘信息、商家入驻等',
                    hasMobile: true
                }
            },
            {
                path: 'Myactivity',
                name: 'Myactivity',
                component: () => import('@/page/member/activity/act_def.vue'),
                meta: {
                    hasMobile: true,
                    title: '我的活动|会员中心|贸友圈',
                },
            },
            {
                path: '/share/:all',
                name: 'share',
                component: () => import('@/page/invite/index'),
                // beforeEnter: (to,from,next)=>{
                //     alert(1111)
                //     /**
                //      * aId => 文章id
                //      * uId => 用户id
                //      * invitedId =>邀请id
                //      */
                //     // console.log(decrypt(to.params.all).split('/'))
                //     let all=decrypt(to.params.all).split('/')
                //     let aId=all[0]
                //     let uId=all[1]
                //     let invitedId=all[2]

                //     if(invitedId && invitedId != -1){

                //         if(!store.state.isLogin){

                //             sessionStorage.setItem("setinvitedId",invitedId)

                //         }	
                //     }
                //     if(aId=='only'){
                //         next('/')
                //     }else if(aId=='u'){
                //         next(`/u/${uId}`)
                //     }else{
                //         next(`/articleDetails/${aId}/${uId}`)
                //     }

                // },
            },
            // {
            // 	path:'/u/:hisIndex',
            // 	name: 'newHisIndex',
            // 	meta:{isMember:true},
            // 	component: () => import('@/page/member/hisHomepage/index')
            // },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import('@/page/member/settings/settings'),
                redirect: '/member/settings/settingsIndex',
                children: [{
                    path: 'settingsIndex',
                    component: () => import('@/page/member/settings/settingsIndex')
                },
                {
                    path: 'changePassword',
                    name: 'ChangePassword',
                    component: () => import('@/page/member/settings/changePassword')
                },
                {
                    path: 'changePasswordSecond',
                    name: 'ChangePasswordSecond',
                    component: () => import('@/page/member/settings/changePasswordSecond')
                },
                {
                    path: 'changePasswordFinish',
                    name: 'ChangePasswordFinish',
                    component: () => import('@/page/member/settings/changePasswordFinish')
                },
                {
                    path: 'modifyMobile',
                    name: 'ModifyMobile',
                    component: () => import('@/page/member/settings/modifyMobile')
                },
                {
                    path: 'modifyMobileSecond',
                    name: 'ModifyMobileSecond',
                    component: () => import('@/page/member/settings/modifyMobileSecond')
                },
                {
                    path: 'modifyMobileFinish',
                    name: 'ModifyMobileFinish',
                    component: () => import('@/page/member/settings/modifyMobileFinish')
                },
                {
                    path: 'modifyEmail',
                    name: 'ModifyEmail',
                    component: () => import('@/page/member/settings/modifyEmail')
                },
                {
                    path: 'modifyEmailSecond',
                    name: 'ModifyEmailSecond',
                    component: () => import('@/page/member/settings/modifyEmailSecond')
                },
                {
                    path: 'modifyEmailFinish',
                    name: 'ModifyEmailFinish',
                    component: () => import('@/page/member/settings/modifyEmailFinish')
                },
                ]
            },
        ]
    },


    {
        path: '/memberPackage',
        name: 'MemberPackage',
        component: () => import('@/page/products/memberPackage'),
        meta: {
            title: 'VIP会员福利-贸友圈',
            keywords: '贸友圈VIP会员福利，贸友圈88VIP会员，贸友圈988VIP会员，贸友圈1988VIP会员',
            description: '贸友圈致力为VIP会员谋福利，帮你把关筛选更好的第三方产品服务且更低价，多分享多赚钱还能赢得超额返现！',
            hasMobile: true
        }
    },


    {
        path: '/orderPayment',
        name: 'OrderPayment',
        component: () => import('@/page/member/payment/orderPayment.vue'),
        meta: {
            hasMobile: true
        }
    },

    {
        path: '/orderCompleted',
        name: 'OrderCompleted',
        component: () => import('@/page/member/payment/orderCompleted.vue'),
        meta: {
            hasMobile: true
        }
    },

    {
        path: '/searchEmailIndex',
        name: 'SearchEmailIndex',
        component: () => import('@/page/searchEmail/searchEmailIndex'),
        redirect: '/productSearch',
        children: [{
            path: '/productSearch',
            name: 'ProductSearch',
            component: () => import('@/page/searchEmail/productSearch')
        },
        {
            path: '/searchEmail',
            name: 'SearchEmail',
            component: () => import('@/page/searchEmail/searchEmail')
        },
        {
            path: '/urlSearchEmail',
            name: 'URLSearchEmail',
            component: () => import('@/page/searchEmail/urlSearchEmail')
        },
        {
            path: '/socialSearchEmail',
            name: 'SocialSearchEmail',
            component: () => import('@/page/searchEmail/socialSearchEmail')
        },
        {
            path: '/nameSearchEmail',
            name: 'NameSearchEmail',
            component: () => import('@/page/searchEmail/nameSearchEmail')
        },
        {
            path: '/linkedInSearchEmail',
            name: 'LinkedInSearchEmail',
            component: () => import('@/page/searchEmail/linkedInSearchEmail')
        }
        ],
        meta: {
            title: '全网搜邮-贸友圈｜找买家联系方式更精准更实惠',
            keywords: '贸友圈，全网搜邮，怎么找国外买家联系方式，怎么找采购商邮箱，外贸客户开发，找采购商负责人联系方式',
            description: '贸友圈【全网搜邮】支持按产品名称、公司名称、网址、社交主页、姓名职称等多种搜索方式查找买家邮箱，含姓名+职称+邮箱+社交主页等销售线索，搜索结果可一键检测、一键导出、一键发送！'
        }
    },
    {
        path: '/emailCheck',
        name: 'EmailCheck',
        component: () => import('@/page/emailCheck/emailCheckIndex'),
        meta: {
            title: '邮箱检测&群发-贸友圈｜批量检测一键群发邮件营销',
            keywords: '贸友圈，免费检测邮箱,邮箱地址检测,邮箱检测工具,批量检测邮箱软件,邮件群发，邮件营销',
            description: '贸友圈【邮箱检测】是在线检测邮箱地址是否有效的验证工具,可快速验证用户和邮箱是否真的存在;除了单个邮箱免费检测,还可批量导入邮箱地址批量检测并将检测结果发给你!'
        }
    },
    {
        path: '/customsData',
        name: 'CustomsData',
        component: () => import('@/page/customsData/customsDataIndex'),
        meta: {
            title: '海关数据-贸友圈｜更新快、更好用、大福利',
            keywords: '贸友圈，查询全球海关数据，海关进出口数据，免费权威海关数据，进出口贸易数据，国外采购商信息',
            description: '贸友圈为外贸人谋福利啦，发现最佳采购商，最新全球各国海关数据，数据多、更新快、效果好好，助您销售业绩增长！'
        }
    },
    {
        path: '/customsCode',
        name: 'CustomsCode',
        component: () => import('@/page/customsCode/newcustomsCodeIndex'),
        meta: {
            title: '免费HS海关编码查询-贸友圈｜申报实例、申报要素、退税率查询',
            keywords: '贸友圈，HS海关编码查询,HS Code查询,申报实例，申报要素，出口退税率查询，海关编码查询，商品编码查询',
            description: '贸友圈为您整理了中国海关最新的HS编码数据库，含申报实例、申报要素、进出口退税率、关税等，还可查海外多国的商品编码查询，HS CODE查询，HS编码查询',
            hasMobile: true
        }
    },
    {
        path: '/act/join',
        name: 'joninAct',
        component: () =>import('@/page/act/join_jump.vue'),
    },


]


export default routesPC