import {
	createRouter,
	createWebHistory
} from 'vue-router'
import store from "@/store/store.js"
import http from "@/components/api/http"
import BlackList from './RouterBlackList';
import setTile from './setTitle';
import convert from "./convert"

import { decrypt } from '@/utils/CryptoJS'
import routesPC from "./routesPC.js"
import routesM from "./routesM.js"

let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

const router = createRouter({
	history: createWebHistory(),
	routes: routesPC.concat(routesM)

});

router.beforeEach((to, from, next) => {
	if(to.path.indexOf("/act/join")!=-1){//活动的跳转页，本向不分客户端
	   next();
	}
	let state = BlackList(isMobile, to, from, next)
	if (state == "toLogin") {//普通页面登录
		//黑名单 去登陆页面
		if (isMobile == null) {
			next("/login")
		} else {
			next("/m/login")
		}
		return false
	} else if (state == "asyncLogin") { //ajax异步登录
		store.commit("indexLogin", true)
		next(false)
		return false
	} else {
		if (localStorage.getItem("store") == null) {
			// 登录过期删除localStorage
			store.commit("delToken")
		}
		//
		convert(isMobile, to, from, next) //PC 转 移动端
		setTile(to, from, next) //设置Title

	}

})
router.afterEach(() => {

	document.body.scrollTop = '';

	document.documentElement.scrollTop = '';
})
// isMobile==null ? routerPC :routerM
export default router
