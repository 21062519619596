
import store from "@/store/store.js"
import { decrypt } from '@/utils/CryptoJS'
//
export default function convert(isMobile, to, from, next) {
    if (isMobile == null) {
        if (to.path.indexOf('/m/') >= 0) {
            if (to.path == '/m/circle') {
                next({
                    path: '/'
                })
            } else {
                if (to.meta.Newlink) {
                    next({
                        path: to.path.slice(22),
                        query: to.query
                    })
                } else if (to.meta.link) {
                    next({
                        path: to.path.slice(14),
                        query: to.query
                    })
                    // rewardHomepage/rewardDetail
                } else if (to.meta.Reward) {
                    next({
                        path: '/rewardDetail/' + to.params.articleId,
                    })

                } else {
                    next({
                        path: to.path.slice(2),
                        query: to.query
                    })
                }
            }
        } else {
            if (to.name == "share") {

                let all = decrypt(to.params.all).split('/')
                let aId = all[0]
                let uId = all[1]
                let invitedId = all[2]
                let URl = all[3]
                if (invitedId != -1) {
                    if (!store.state.isLogin) {
                        sessionStorage.setItem("setinvitedId", invitedId)
                    }
                }

                if (URl == "pintuanDetil") {
                    next({
                        path: "/pintuanDetil",

                    })

                } else if (URl == "articleDetails") {
                    next(`/m/articleDetails/${aId}`)
                } else if (URl == 'only') {
                    next('/')
                } else if (URl == "u") {
                    next(`/u/${uId}`)
                } else if (URl == 'rewardDetail') {
                    next(`/m/rewardDetail/${aId}`)
                }


            } else {
                next()
            }


        }
    } else {

        document.querySelector('#app').className = 'bgcolor1'
        if (to.path.indexOf('/m/') >= 0) {
            next()

        } else {

            // ||to.meta.newhasMobile||to.meta.newPage
            if (to.meta.hasMobile) {
                next({
                    path: '/m' + to.path,
                    query: to.query
                })

            } else if (to.meta.isMember) {
                let url = to.fullPath.replace('/member', '/m')
                next(url)
            } else if (to.name == "share") {

                let all = decrypt(to.params.all).split('/')
                let aId = all[0]
                let uId = all[1]
                let invitedId = all[2]
                let URl = all[3]

                if (invitedId != -1) {
                    if (!store.state.isLogin) {
                        sessionStorage.setItem("setinvitedId", invitedId)

                    }
                }
                if (URl == "pintuanDetil") {
                    next({
                        path: "/m/pintuanDetil",
                        query: {
                            pintuanId: aId,
                            userId: uId,
                            invitedId: invitedId
                        }
                    })

                } else if (URl == "articleDetails") {
                    next(`/m/articleDetails/${aId}`)
                } else if (URl == 'only') {
                    next('/m/')
                } else if (URl == "u") {
                    next(`/m/hisIndex?hisUserId=${uId}`)
                } else if (URl == 'rewardDetail') {
                    next(`/m/rewardDetail/${aId}`)
                }



            } else {
                next({
                    path: '/m/'
                })
            }
        }
    }
}