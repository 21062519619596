<template>
	<!-- <div style="background-color: #ccc;"> -->
	<router-view></router-view>
<!-- </div> -->
	<AsyncLogin  v-show="judge" @closeShow="closeLogin"></AsyncLogin>
	
</template>

<script>
	import AsyncLogin from '@/components/login/login.vue'
	import DevicePixelRatio from "@/utils/DevicePixelRatio.js"
	export default {
		name: 'App',
		components: {
			AsyncLogin
		},
		data(){
			return{
				judge:''
			}
		},
		watch:{
			'$store.state.indexLogin':{
				deep:true,
				handler(newvalue,oldvalue){
					this.judge=newvalue
				}	
			},

		},
		
		updated(){
			this.judge=this.judge
		},
		methods:{
			closeLogin(value) {
				if (value == 'close') {
					this.$store.commit("indexLogin",false)
				}
			},
			bodyScale  () {
				var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下			的可是区域宽度
				var scale = devicewidth / 1920; // 分母——设计稿的尺寸
			//	document.body.style.zoom = scale;//放大缩小相应倍数
			}
		},
		created() {
			/***
			 * 清除历史cookie
			 * 
			 */
	
		if (this.$cookies.get('store')) {
			 
			this.$cookies.set('store',{})
		}
		let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
		if (!isMobile) {
		new DevicePixelRatio().init()
		this.bodyScale()
		}
	
			// debugger	
			// 在页面刷新时将vuex里的信息保存到sessionStorage里
			// beforeunload事件在页面刷新时先触发
			// window.addEventListener('pagehide', () => {
			// 	sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			// })
			// // 在页面加载时读取sessionStorage里的状态信息
			// if (sessionStorage.getItem('store')) {
			// 	this.$store.replaceState(
			// 		Object.assign({},
			// 			this.$store.state,
			// 			JSON.parse(sessionStorage.getItem('store'))
			// 		)
			// 	)
			// }
			// 	console.log(JSON.parse(localStorage.getItem('store')) ,'sss')
			// this.$cookies.config('1d')
			// 在页面刷新时将vuex里的信息保存到sessionStorage里
			// beforeunload事件在页面刷新时先触发
			
			// let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			// console.log(isIOS)
			// if (isIOS) {
			// 	window.addEventListener('pagehide', () => {
			// 		this.$cookies.set('store', JSON.stringify(this.$store.state))
			// 		// alert(this.$cookies.get('store'))
			// 	})
			// } else {
			// 	window.addEventListener('beforeunload', () => {
					
			// 		this.$cookies.set('store', JSON.stringify(this.$store.state))
			// 		// alert(this.$cookies.get('store'))
			// 	})
			// }
			
			// if (this.$cookies.get('store')) {
			// 	this.$store.replaceState(
			// 		Object.assign({},
			// 			this.$store.state,
			// 			this.$cookies.get('store')
			// 		)
			// 	)	
			// }
		}
	}

</script>

<style>

.pointer{
  cursor:pointer;
}
.ellipsis-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content:center;
}
.hover-blue:hover .hover-blue1{
  color:#409eff !important;
}
.hover-blue:hover{
	cursor: pointer;
}
.inline-block{
  display:inline-block;
}
.text-center{
  text-align: center;
}
.text-12px{
  font-size:12px;
}
.text-white{
  color: white;
}

</style>
