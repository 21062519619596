import router from "@/router";
import store from "@/store/store"
let timer=null;
// 重写localStorage，并设置过期时间
let getItem = localStorage.getItem.bind(localStorage)
let setItem = localStorage.setItem.bind(localStorage)
let removeItem = localStorage.removeItem.bind(localStorage)
let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
localStorage.getItem = function (keyName) {

  let expires = getItem(keyName + '_expires')//保存的时间

  
  let curTime=new Date().getTime() //当前时间



  if (expires && (curTime > expires)) {
    /**
     *  切换路由清除localStorag
     */
   
    localStorage.clear()
    removeItem(keyName)
    removeItem(keyName + '_expires')
    store &&store.commit("delToken")
    clearInterval(timer)
  }
    /**
   * 计时器 自动清除localStorage
   * 默认 秒 
   */
  if( expires){
    let _expires= calculateMinutes(expires, curTime)//保存剩下相差秒数
    timer && clearInterval(timer)
    timer=setInterval(()=>{
     
          if(_expires == 0){
            store && store.commit("delToken")
            localStorage.clear()
            // router.push('/')
            window.location.href='/'
                    // if(isMobile==null){
                    //   router.push('/customsData')
                    // }else{
                    //     router.push('/m/logi')
                    // }
            clearInterval(timer)
            return 
          }
        _expires--;
      // console.log(_expires)
    },1000)
   
  }else{
    clearInterval(timer)
    return
  }

 
  return getItem(keyName)
}

localStorage.setItem = function (keyName, keyValue, expires) {
  if (typeof expires !== 'undefined') {
   
    let expiresDate = expires*1000 + +new Date()
    setItem(keyName + '_expires', expiresDate)
    
    // setItem('_expires', expires)
  
  }
 
  
  return setItem(keyName, keyValue)
}

function calculateMinutes(time1, time2) {

  const date1 = time1;
  const date2 = time2;

  const diff = Math.abs(date1 - date2);
/**
 * 返回秒
 */
  return Math.floor(diff / (1000 ));
}






